import { createElement } from 'react'
import { twMerge } from 'tailwind-merge'
import { componentMap, TypographyProps } from './types'
import { sTypography } from './styles'

export default function Typography({
  variant = 'P',
  component,
  color,
  className,
  children,
}: TypographyProps) {
  const htmlElement = component || componentMap[variant]

  return createElement(
    htmlElement,
    {
      className: twMerge(sTypography({ variant }), color, className),
    },
    children
  )
}
