import { useConfirmModal } from '@hooks/useConfirm'
import Button from '@ui/atoms/Button'
import Typography from '@ui/atoms/Typography'
import Modal from '@ui/organisms/Modal/Modal'
import { useTranslation } from 'react-i18next'

const ConfirmModal = () => {
  const { isOpen, confirm, close, title } = useConfirmModal()
  const { t } = useTranslation()

  return (
    <Modal
      open={isOpen}
      onClose={close}
      className="w-[618px] rounded-2xl"
      description={
        <div className="flex flex-col gap-2 pb-8">
          <Typography
            color="text-gray-900"
            variant="H4"
            className="text-center"
          >
            {t(`confirm.title`)}
          </Typography>
          <Typography
            color="text-gray-900"
            variant="P"
            className="text-center font-normal"
          >
            {t(`confirm.desc`)}
          </Typography>
          <Typography color="text-gray-900" variant="P" className="text-center">
            {title}
          </Typography>
        </div>
      }
    >
      <div className="flex w-full flex-row justify-center gap-8">
        <Button
          type="button"
          size="small"
          intent="secondary"
          onClick={confirm}
          text={t(`confirm.next`)}

          // className="relative inset-0 flex w-full cursor-pointer flex-col items-center justify-center gap-0 rounded-xl border-2 border-red-500 bg-white px-8 py-2 font-bold uppercase text-red-500 shadow-none transition-all hover:border-red-700 hover:bg-red-700 hover:text-white disabled:pointer-events-none disabled:border-gray-500 disabled:text-gray-600"
        />
        <Button
          type="button"
          size="small"
          intent="secondary"
          onClick={close}
          className="border-gray-500 bg-white text-gray-900 shadow-none transition-all hover:border-gray-700 hover:bg-gray-700 hover:text-white"
          text={t(`confirm.cancel`)}
        />
      </div>
    </Modal>
  )
}

export default ConfirmModal
