import useConfig from '@api/useConfig'
import { useMemo } from 'react'
import { getCurrencyFormatter } from '@utils/currencyFormat'

const useCurrencyFormat = () => {
  const config = useConfig()

  const formatter = useMemo(() => {
    return getCurrencyFormatter(
      config.data?.locale || 'de',
      config.data?.currency || 'EUR'
    )
  }, [config.data?.locale, config.data?.currency])

  return formatter.format
}

export default useCurrencyFormat
