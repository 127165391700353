import { cva } from 'class-variance-authority'

export const sTypography = cva('text-left', {
  variants: {
    variant: {
      H1: ['font-head', 'font-bold', 'text-[2rem]', 'leading-[3rem]'],
      H2: [
        'font-text',
        'font-bold',
        'text-[1.875rem]',
        'leading-[2.5rem]',
        'tracking-tight',
      ],
      H3: ['font-text', 'font-bold', 'text-[1.75rem]', 'leading-[2.25rem]'],
      H4: ['font-text', 'font-bold', 'text-[1.5rem]', 'leading-[2rem]'],
      H5: ['font-text', 'font-bold', 'text-[1.25rem]', 'leading-[1.75rem]'],
      P: ['font-text', 'font-bold', 'text-[1.125rem]', 'leading-[1.5rem]'],
      PM: ['font-text', 'font-medium', 'text-[1.25rem]', 'leading-none'],
      CB: ['font-text', 'font-bold', 'text-[0.875rem]', 'leading-[1rem]'],
    },
  },
})
