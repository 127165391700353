import { useAtomValue, useSetAtom } from 'jotai'
import { initialParamsAtom, paramsAtom } from '@jotai/config'
import { useEffect, useMemo } from 'react'
import { historyAtom } from '@jotai/history'
import { applyOptionAction } from '@utils/paramActions'

export const useParamsUpdate = () => {
  const initial = useAtomValue(initialParamsAtom)
  const setParams = useSetAtom(paramsAtom)
  const history = useAtomValue(historyAtom)
  const params = useMemo(() => {
    if (!initial?.success) {
      return null
    }

    const actionsFromHistory = history
      .filter((item) => item.action)
      .map((item) => item.action)
    if (actionsFromHistory?.length > 0) {
      return actionsFromHistory.reduce((acc, action) => {
        return applyOptionAction(acc, action)
      }, initial.data)
    }

    return initial.data
  }, [initial, history])

  useEffect(() => {
    setParams(params)
  }, [params])
}

const useParams = ({
  onError,
}: {
  onError?: (error: Error) => void
} = {}) => {
  const params = useAtomValue(paramsAtom)

  useEffect(() => {
    if (!params) {
      const error = new Error('Params not set')
      if (onError) {
        onError(error)
        return
      }
      throw error
    }
  }, [])
  return params
}

export default useParams
