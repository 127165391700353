import React from 'react'
import { twMerge } from 'tailwind-merge'
import { PaperProps } from './types'
import { sPaper } from './styles'

export const Paper = React.forwardRef<HTMLDivElement, PaperProps>(
  ({ children, className, intent, shadow }, ref) => {
    return (
      <div className={twMerge(sPaper({ intent, shadow }), className)} ref={ref}>
        {children}
      </div>
    )
  }
)

export default Paper
