import validateCondition from '@utils/validateCondition'
import { ConditionDictionary, ConditionValues } from '@type/conditions'
import { StepOption } from '@type/config'

interface Args {
  option: StepOption
  values: ConditionValues
  conditions: ConditionDictionary
}

export default function shouldDisplayOption({
  option,
  values,
  conditions,
}: Args) {
  if (!option.condition) return true

  return validateCondition(option.condition, values, conditions).result
}
