import { atom } from 'jotai'

export interface CaptionsAtom {
  enabled: boolean
  control: boolean
}

export const captionsAtom = atom<CaptionsAtom>({
  enabled: true,
  control: true,
})
