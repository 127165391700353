import AppConfig from '@/AppConfig'

export const getStrapiStepUrl = ({
  id,
  locale,
}: {
  id: string | number
  locale?: string
}) => {
  const url = `${AppConfig.strapiUrl}/admin/content-manager/collectionType/api::cwa-step.cwa-step/${id}`
  if (locale) {
    return `${url}?plugins[i18n][locale]=${locale}`
  }
  return url
}

export const getElaiVideoUrl = (id: string) => `https://app.elai.io/video/${id}`
