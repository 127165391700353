import { AxiosInstance } from 'axios'
import z from 'zod'

export type IdMap = {
  conditions: Record<string, number>
  steps: Record<string, number>
  images: Record<string, number>
}

export type StrapiImage = {
  id: number
  attributes: {
    name: string
    mime: string
    url: string
  }
}

export type ImagesMap = Record<string, StrapiImage>

export type ArrayRelation<T = any> = {
  data:
    | {
        id: number
        attributes: T
      }[]
    | null
}

export type Relation = {
  data: {
    id: number
  } | null
}

export type ConditionRelation = {
  data: {
    id: number
    attributes: {
      name: string
    }
  } | null
}

export type ImagesRelation = {
  data: StrapiImage[] | null
}

export type ImageRelation = {
  data: StrapiImage | null
}

export type ScriptLoggerLevel =
  | 'text'
  | 'info'
  | 'loading'
  | 'warn'
  | 'error'
  | 'success'

export interface ScriptLoggerOptions {
  level: ScriptLoggerLevel
}

export type ScriptLogger = (
  message: string,
  options?: ScriptLoggerOptions
) => void

export type ScriptLogMessage = {
  message: string
  options: ScriptLoggerOptions
}

export type MigrateParams = {
  fromApi: AxiosInstance
  toApi: AxiosInstance
  idMap: IdMap
  log: ScriptLogger
  locale?: string
}

export type TranslateParams = {
  api: AxiosInstance
  idMap: IdMap
  log: ScriptLogger
  sourceLocale: string
  targetLocale: string
}

export type StepOptionResponse = {
  id: number
  disabled: Relation
  locked: Relation
  condition: Relation
  nextStep: Relation
  images: ImagesRelation
}

export type VideoResponse = {
  id: number
  condition: Relation
  url: string
  name: string
  elaiId: string
}

export type StepResponseItem = {
  id: number
  attributes: {
    locale: string
    title: string
    options: StepOptionResponse[]
    videos: VideoResponse[]
    localizations?: {
      data: StepResponseItem[] | null
    }
  }
}

export type StrapiPaginatedResponse<T> = {
  data: T[]
  meta: {
    pagination: {
      page: number
      pageSize: number
      pageCount: number
      total: number
    }
  }
}

export type StepResponse = StrapiPaginatedResponse<StepResponseItem>

type Condition = {
  id?: number
  __component: string
  condition: ConditionRelation
  relation: {
    id: number
    negation: boolean
    condition: ConditionRelation
  }[]
}

export type ConditionResponseItem = {
  id: number
  attributes: {
    name: string
    conditions: Condition[]
  }
}

export type ConditionResponse = StrapiPaginatedResponse<ConditionResponseItem>

const apiConfigSchema = z.object({
  api: z.string(),
  token: z.string(),
})

export const migrationConfigSchema = z.object({
  from: apiConfigSchema,
  to: apiConfigSchema,
  locale: z.string().optional(),
})

export type MigrationApiConfig = z.infer<typeof migrationConfigSchema>
