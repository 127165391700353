import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useAtomValue, useSetAtom } from 'jotai'

import { initialParamsAtom } from '@jotai/config'
import { captionsAtom, CaptionsAtom } from '@jotai/captions'
import useConfig from '@api/useConfig'

import HydrateHistory from '@components/HydrateHistory/HydrateHistory'
import Step from '@components/Step/Step'

import Paper from '@ui/atoms/Paper'
import ZodErrorDisplay from '@ui/organisms/ZodErrorDisplay'
import ConfirmModal from '@components/ConfirmModal/ConfirmModal'

export default function HomePage() {
  const { t, i18n } = useTranslation()
  const params = useAtomValue(initialParamsAtom)
  const setCaptions = useSetAtom(captionsAtom)

  const { data } = useConfig()

  useEffect(() => {
    if (!data) {
      return
    }
    // Extend local with translations from config
    i18n.addResourceBundle(
      i18n.language,
      'translation',
      data.translations,
      true,
      true
    )

    // Set Captions settings
    if (data.captions) {
      let newCaptions: CaptionsAtom = {
        enabled: true,
        control: true,
      }
      if (data.captions.toLocaleLowerCase().includes('off'))
        newCaptions.enabled = false
      if (data.captions.includes('always')) newCaptions.control = false

      setCaptions(newCaptions)
    }
  }, [data])

  if (!data?.steps) return null

  if (params?.success === false) {
    return (
      <Paper>
        <ZodErrorDisplay
          message={t('errors.invalidParams')}
          error={params.error}
        />
      </Paper>
    )
  }

  return (
    <HydrateHistory
      initialStep={params?.data?.initialStep || data?.initialStep}
    >
      <Step />
      <ConfirmModal />
    </HydrateHistory>
  )
}
