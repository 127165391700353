import useStepHistory from '@hooks/useStepHistory'

import OptionFlow from '@ui/options/OptionFlow'
import Headline from '@ui/organisms/Headline'
import Section from '@ui/wrappers/Section'
import StepInfo from '@ui/molecules/StepInfo/StepInfo'

export default function TemplateFlows() {
  const { current, selectOption, options } = useStepHistory()
  if (!current?.step) return null
  const { step } = current

  return (
    <>
      <StepInfo step={step} />
      <Headline>{step.title}</Headline>
      <Section className="mt-4 flex flex-col gap-8 px-24">
        {options.map((option) => (
          <OptionFlow
            key={option.title + option.value}
            confirm={step.confirm}
            onClick={() => selectOption(option)}
            {...option}
          />
        ))}
      </Section>
      <div className="pb-16" />
    </>
  )
}
