import { twMerge } from 'tailwind-merge'
import { SectionProps } from './types'
import { sOuter, sInner } from './styles'

export default function Section({
  children,
  id,
  classNameOuter,
  className,
}: SectionProps) {
  return (
    <section id={id} className={twMerge(sOuter(), classNameOuter)}>
      <div className={twMerge(sInner(), className)}>{children}</div>
    </section>
  )
}
