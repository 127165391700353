import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import translationEn from './locale/en/translation.json'
import { getParsedUrlParams } from '@hooks/useSetInitialParams'
import { SupportedLocales } from '@cwa/types'

export type Locales = (typeof SupportedLocales)[keyof typeof SupportedLocales]

declare module 'i18next' {
  // Extend CustomTypeOptions
  interface CustomTypeOptions {
    // custom namespace type, if you changed it
    defaultNS: 'translation'
    // custom resources type
    // resources: {
    //   translation: typeof translationEn
    // }
    // other
  }
}

const getLng = () => {
  const locale = getParsedUrlParams()?.locale
  if (typeof locale === 'string') {
    return locale
  }
  return SupportedLocales.de
}

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    // the translations
    // (tip move them in a JSON file and import them,
    // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
    resources: {
      en: {
        translation: translationEn,
      },
    },
    lng: getLng(), // if you're using a language detector, do not define the lng option
    fallbackLng: SupportedLocales.de,

    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
  })
