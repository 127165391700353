import { ZodError } from 'zod'

interface Props {
  error: ZodError
  message?: string
}

export default function ZodErrorDisplay({ error, message }: Props) {
  return (
    <div className="flex flex-col">
      {message && <span className="font-bold text-red-600">{message}</span>}
      {error.issues.map((issue) => (
        <div className="space-x-3" key={issue.path.join('.')}>
          <span className="font-bold">{issue.path.join('.')}</span>
          <span>{issue.message}</span>
        </div>
      ))}
    </div>
  )
}
