export const bool = (value: any): boolean => {
  if (typeof value === 'string') {
    if (value === 'true') return true

    if (value === 'false') return false
    if (value === 'undefined') return false
    if (value === 'null') return false

    return Boolean(parseInt(value))
  }

  return Boolean(value)
}
