import { LocaleCustomProps } from '@components/LocaleSwitcher/LocaleButton'
import useAuth from '@hooks/useAuth'
import { Suspense, lazy } from 'react'
import { Navigate } from 'react-router'

interface Props {
  children?: React.ReactNode
  hideNavigation?: boolean
  noAuth?: boolean
  showLocale?: boolean
  localeConfig?: LocaleCustomProps
}

const LazyNavOverlay = lazy(() => import('@components/Navigation/NavOverlay'))

export default function LayoutAdmin({
  children,
  hideNavigation,
  noAuth,
  showLocale,
  localeConfig,
}: Props) {
  const { isValid } = useAuth()

  if (!noAuth && isValid === false) return <Navigate to="/auth" />
  if (!noAuth && isValid === null) return <></>

  return (
    <Suspense fallback={null}>
      {!hideNavigation && (
        <LazyNavOverlay localeConfig={localeConfig} showLocale={showLocale} />
      )}
      {children}
    </Suspense>
  )
}
