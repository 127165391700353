import { useStepHistoryHydrate } from '@hooks/useStepHistory'

interface Props {
  initialStep: string
  children?: React.ReactNode
}

export default function HydrateHistory({ initialStep, children }: Props) {
  useStepHistoryHydrate(initialStep)
  return <>{children}</>
}
