import { useRouteError } from 'react-router'
import DisplayError from './DisplayError'

const RouteErrorBoundary = () => {
  const error = useRouteError()
  console.log(error)
  if (typeof error === 'string') return <DisplayError>{error}</DisplayError>
  if (error instanceof Error)
    return <DisplayError>{error.message}</DisplayError>
  return <DisplayError>Ups something went wrong</DisplayError>
}

export default RouteErrorBoundary
