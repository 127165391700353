import { StepHistory } from '@type/StepHistory'
import { getValuesFromHistory } from '@utils/optionUtils'
import { useMemo } from 'react'

const useHistoryValues = (history: StepHistory) => {
  const values = useMemo(() => getValuesFromHistory(history), [history])

  const order = values.order?.value ? Number(values.order.value) : undefined
  const orderValues = useMemo(
    () => getValuesFromHistory(history, false, order),
    [history, order]
  )

  return {
    values,
    order,
    orderValues,
  }
}

export default useHistoryValues
