import { VariantProps } from 'class-variance-authority'
import { sTypography } from './styles'
import { ColorText } from '@ui/atoms/Colors/types'

type Component =
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'h5'
  | 'h6'
  | 'h7'
  | 'p'
  | 'span'
  | 'li'

type VariantNullable = Pick<
  VariantProps<typeof sTypography>,
  'variant'
>['variant']

export type Variant = Exclude<VariantNullable, undefined | null>

export const componentMap: {
  [key in Variant]: Component
} = {
  H1: 'h1',
  H2: 'h2',
  H3: 'h3',
  H4: 'h4',
  H5: 'h5',
  P: 'p',
  PM: 'p',
  CB: 'p',
}

export interface TypographyProps extends VariantProps<typeof sTypography> {
  children: React.ReactNode
  component?: Component
  variant?: Variant
  color?: ColorText
  className?: string
}
