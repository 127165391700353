import { bool } from '@utils/bool'

const envNumber = (value: string | undefined): number | undefined => {
  if (value === undefined) return undefined

  const num = Number(value)

  return !Number.isFinite(num) ? undefined : num
}

const AppConfig = {
  dev: bool(import.meta.env.VITE_DEV),
  appName: APP_NAME,
  appVersion: APP_VERSION,
  appBuild: APP_BUILD,

  strapiUrl: import.meta.env.VITE_STRAPI_URL,
  frameImagesUrl: import.meta.env.VITE_FRAME_IMAGES_URL,
  sio: {
    path: '/api/socket.io',
  },
  migrate: {
    from: {
      api: import.meta.env.VITE_MIGRATE_FROM_URL,
      token: import.meta.env.VITE_MIGRATE_FROM_TOKEN,
    },
    to: {
      api: import.meta.env.VITE_MIGRATE_TO_URL,
      token: import.meta.env.VITE_MIGRATE_TO_TOKEN,
    },
  },
  resultApi: {
    url: import.meta.env.VITE_RESULT_API_URL,
    key: import.meta.env.VITE_RESULT_API_KEY,
  },
  admin: {
    updateBatchSize: 20,
  },
  isDev: (params?: { dev?: boolean } | null) => {
    const paramsDev = params?.dev

    return paramsDev === undefined ? AppConfig.dev : bool(paramsDev)
  },
  player: {
    defaultSkipButton: 'auto',
    devModeSkipButton: 'auto',
    skipCountdown: 4, // skip unlocks after x seconds
    skipOffMinVideoDuration: 4, // if video duration is less then x seconds keep skip OFF
  },
  sentry: {
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1,
    tracesSampleRate:
      envNumber(import.meta.env.VITE_SENTRY_TRACES_SAMPLE_RATE) || 0.1,
  },
  environment: import.meta.env.VITE_ENVIRONMENT || 'production',
  initialParamsKey: import.meta.env.VITE_INITIAL_PARAMS_KEY || 'sp',
} as const

export default AppConfig
