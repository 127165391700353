import Typography from '@ui/atoms/Typography'
import Paper from '@ui/atoms/Paper'
import Icon from '@ui/atoms/Icon'

import { iconsMap, sToast } from './styles'
import { Props } from './types'
import { twMerge } from 'tailwind-merge'
import { useTranslation } from 'react-i18next'

export default function ToastComponent({
  title,
  desc,
  icon,
  intent = 'outline',
  type = 'default',
  loading = false,
}: Props) {
  return (
    <Paper
      shadow="paper"
      className={twMerge(
        sToast({
          intent,
          type,
        }),
        'shadow-none'
      )}
    >
      <div className="flex flex-col gap-4">
        <div className="flex items-center gap-4">
          {icon !== null && (
            <Icon
              name={icon || iconsMap[type]}
              className={twMerge(loading && 'animate-spin')}
            />
          )}
          <div className="flex select-none flex-col gap-1">
            <Typography variant="CB" className="leading-tight">
              {title}
            </Typography>
            {desc && (
              <Typography variant="P" className="leading-tight opacity-75">
                {desc}
              </Typography>
            )}
          </div>
        </div>
      </div>
    </Paper>
  )
}
