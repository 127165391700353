import { atom, useAtom, useSetAtom } from 'jotai'
import { useCallback } from 'react'

interface Atom {
  isOpen: boolean
  title: string
  onConfirm?: () => void
}

export const confirmAtom = atom<Atom>({
  isOpen: false,
  title: '',
})

export const useConfirm = () => {
  const setConfirmAtom = useSetAtom(confirmAtom)

  const open = ({
    title,
    onConfirm,
  }: {
    title: string
    onConfirm?: () => void
  }) => {
    setConfirmAtom({
      isOpen: true,
      title,
      onConfirm,
    })
  }

  return { open }
}

export const useConfirmModal = () => {
  const [{ isOpen, title, onConfirm }, setConfirmAtom] = useAtom(confirmAtom)

  const close = useCallback(() => {
    setConfirmAtom({
      isOpen: false,
      title: title,
      onConfirm: undefined,
    })
  }, [title, setConfirmAtom])

  const confirm = useCallback(() => {
    setConfirmAtom({
      isOpen: false,
      title: title,
      onConfirm: undefined,
    })
    onConfirm?.()
  }, [onConfirm, setConfirmAtom, title])

  return { isOpen, confirm, close, title }
}
