/* Import another if needed from https://tabler-icons.io/ */

import {
  IconAdjustments,
  IconAlertCircleFilled,
  IconAlertTriangle,
  IconAlertTriangleFilled,
  IconArrowLeft,
  IconArrowRight,
  IconArrowsMove,
  IconBadgeCc,
  IconBolt,
  IconBrandVimeo,
  IconCheck,
  IconChevronDown,
  IconChevronLeft,
  IconChevronRight,
  IconChevronUp,
  IconCircleCheck,
  IconCircleCheckFilled,
  IconCircleDotted,
  IconCircleX,
  IconDownload,
  IconDownloadOff,
  IconEye,
  IconFlagFilled,
  IconFocus2,
  IconHelpCircle,
  IconHelpCircleFilled,
  IconHome,
  IconInfoSquareFilled,
  IconKey,
  IconKeyOff,
  IconLanguage,
  IconLink,
  IconLoader,
  IconLoader2,
  IconLogout,
  IconMenu2,
  IconPencil,
  IconPlayerPauseFilled,
  IconPlayerPlayFilled,
  IconPlayerSkipBackFilled,
  IconPlayerSkipForwardFilled,
  IconPlayerTrackNextFilled,
  IconPlayerTrackPrevFilled,
  IconRepeat,
  IconSearch,
  IconSettings,
  IconSettingsFilled,
  IconShare,
  IconShoppingCart,
  IconStackPush,
  IconStarFilled,
  IconTrash,
  IconVideo,
  IconWorld,
  IconX,
} from '@tabler/icons-react'

const icons = {
  'alert-circle-filled': IconAlertCircleFilled,
  'alert-triangle-filled': IconAlertTriangleFilled,
  'alert-triangle': IconAlertTriangle,
  'arrow-left': IconArrowLeft,
  'arrow-right': IconArrowRight,
  'arrows-move': IconArrowsMove,
  'badge-cc': IconBadgeCc,
  'brand-vimeo': IconBrandVimeo,
  'chevron-down': IconChevronDown,
  'chevron-left': IconChevronLeft,
  'chevron-right': IconChevronRight,
  'chevron-up': IconChevronUp,
  'circle-check-filled': IconCircleCheckFilled,
  'circle-check': IconCircleCheck,
  'circle-dotted': IconCircleDotted,
  'circle-x': IconCircleX,
  'download-off': IconDownloadOff,
  'flag-filled': IconFlagFilled,
  'focus-2': IconFocus2,
  'help-circle-filled': IconHelpCircleFilled,
  'help-circle': IconHelpCircle,
  'key-off': IconKeyOff,
  'loader-2': IconLoader2,
  'menu-2': IconMenu2,
  'info-square-filled': IconInfoSquareFilled,
  'player-pause-filled': IconPlayerPauseFilled,
  'player-play-filled': IconPlayerPlayFilled,
  'player-skip-back-filled': IconPlayerSkipBackFilled,
  'player-skip-forward-filled': IconPlayerSkipForwardFilled,
  'player-track-next-filled': IconPlayerTrackNextFilled,
  'player-track-prev-filled': IconPlayerTrackPrevFilled,
  'settings-filled': IconSettingsFilled,
  'shopping-cart': IconShoppingCart,
  'stack-push': IconStackPush,
  adjustments: IconAdjustments,
  bolt: IconBolt,
  check: IconCheck,
  download: IconDownload,
  eye: IconEye,
  home: IconHome,
  key: IconKey,
  language: IconLanguage,
  link: IconLink,
  loader: IconLoader,
  logout: IconLogout,
  pencil: IconPencil,
  repeat: IconRepeat,
  search: IconSearch,
  settings: IconSettings,
  share: IconShare,
  star: IconStarFilled,
  trash: IconTrash,
  video: IconVideo,
  world: IconWorld,
  x: IconX,
} as const

export const iconNames = Object.keys(icons) as Array<keyof typeof icons>

export const iconList = Object.fromEntries(
  iconNames.map((name) => [name, name])
) as Record<keyof typeof icons, keyof typeof icons>

export default icons
