import { twMerge } from 'tailwind-merge'
import ButtonOrLink from './ButtonOrLink'
import { ButtonProps } from './types'
import { sButton } from './styles'
import Icon from '@ui/atoms/Icon'

export default function Button({
  text,
  intent = 'primary',
  disabled = false,
  width = 'minimal',
  size = 'large',
  className,
  loading,
  ...props
}: ButtonProps) {
  return (
    <ButtonOrLink
      className={twMerge(
        sButton({
          intent,
          disabled: disabled || loading,
          width,
          size,
        }),
        className
      )}
      disabled={disabled || loading}
      {...props}
    >
      {loading && (
        <div className="absolute left-1/2 top-1/2 flex -translate-x-1/2 -translate-y-1/2 items-center justify-center">
          <Icon
            className="animate-spin"
            color="text-magenta-500"
            name="loader-2"
            size={40}
            stroke={4}
          />
        </div>
      )}
      <span
        className={twMerge(
          'w-full text-center font-text leading-[inherit] transition-opacity',
          loading && 'opacity-0'
        )}
      >
        {text}
      </span>
    </ButtonOrLink>
  )
}
