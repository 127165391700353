import { atom } from 'jotai'

interface Atom {
  valid: boolean | null
  token: string | null
}

export const authAtom = atom<Atom>({
  valid: null,
  token: '',
})
