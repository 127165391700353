import { useEffect, useRef } from 'react'

import useStepHistory from '@hooks/useStepHistory'

export default function TemplateAutomatic() {
  const { current, selectOption, options } = useStepHistory()
  const selected = useRef(false)

  if (!current?.step) return null

  useEffect(() => {
    if (!options[0]) {
      throw new Error('Missing valid option for automatic template')
    }
    // HACK: This is a hack to prevent the automatic template
    // from firing twice in StrictMode
    if (selected.current) return
    selected.current = true
    selectOption(options[0])
  }, [])

  return null
}
