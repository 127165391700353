import { twMerge } from 'tailwind-merge'
import Typography from '@ui/atoms/Typography'
import useStepHistory from '@hooks/useStepHistory'
import Section from '@ui/wrappers/Section'
interface Props {
  children: React.ReactNode
  className?: string
}

export default function Info({ children, className }: Props) {
  const { dev } = useStepHistory()
  if (!dev) return null

  return (
    <Section
      classNameOuter="pointer-events-none absolute left-1/2 z-[100] -translate-x-1/2"
      className="flex items-center justify-center"
    >
      <Typography
        color="text-black"
        className={twMerge(
          'pointer-events-auto absolute left-1/2 top-[28px] w-max -translate-x-1/2 select-text rounded-md bg-white/50 px-2 py-1 text-sm',
          className
        )}
      >
        {children}
      </Typography>
    </Section>
  )
}
