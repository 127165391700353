import TemplateAutomatic from '@ui/templates/Automatic/TemplateAutomatic'
import TemplateFlows from '@ui/templates/Flows/TemplateFlows'

import { lazy } from 'react'

export type StepTemplate =
  | 'AdImage'
  | 'AdMaxTwo'
  | 'Automatic'
  | 'Flows'
  | 'RadioColors'
  | 'RadioGroup'
  | 'RadioGroupFrame'
  | 'RadioHorizontal'
  | 'RadioHorizontalFrame'
  | 'RadioVertical'
  | 'Simple'
  | 'Video'
  | 'VideoFrame'
  | 'VideoHorizontal'
  | 'VideoImageLarge'
  | 'VideoPackage'
  | 'VideoPackage2'
  | 'VideoSquare'

export const templatesMap = {
  AdImage: lazy(() => import('@ui/templates/AdImage/TemplateAdImage')),
  AdMaxTwo: lazy(() => import('@ui/templates/AdMaxTwo/TemplateAdMaxTwo')),
  Automatic: TemplateAutomatic,
  Flows: TemplateFlows,
  RadioColors: lazy(
    () => import('@ui/templates/RadioColors/TemplateRadioColors')
  ),
  RadioGroup: lazy(() => import('@ui/templates/RadioGroup/TemplateRadioGroup')),
  RadioGroupFrame: lazy(
    () => import('@ui/templates/RadioGroupFrame/TemplateRadioGroupFrame')
  ),
  RadioHorizontal: lazy(
    () => import('@ui/templates/RadioHorizontal/TemplateRadioHorizontal')
  ),
  RadioHorizontalFrame: lazy(
    () =>
      import('@ui/templates/RadioHorizontalFrame/TemplateRadioHorizontalFrame')
  ),
  RadioVertical: lazy(
    () => import('@ui/templates/RadioVertical/TemplateRadioVertical')
  ),
  Simple: lazy(() => import('@ui/templates/Simple/TemplateSimple')),
  Video: lazy(() => import('@ui/templates/Video/TemplateVideo')),
  VideoFrame: lazy(() => import('@ui/templates/VideoFrame/TemplateVideoFrame')),
  VideoHorizontal: lazy(
    () => import('@ui/templates/VideoHorizontal/TemplateVideoHorizontal')
  ),
  VideoSquare: lazy(
    () => import('@ui/templates/VideoSquare/TemplateVideoSquare')
  ),
  VideoImageLarge: lazy(
    () => import('@ui/templates/VideoImageLarge/TemplateVideoImageLarge')
  ),
  VideoPackage: lazy(
    () => import('@ui/templates/VideoPackage/TemplateVideoPackage')
  ),
  VideoPackage2: lazy(
    () => import('@ui/templates/VideoPackage2/TemplateVideoPackage2')
  ),
}
