import eventApi from '@api/eventApi'
import useStepHistory from '@hooks/useStepHistory'
import ButtonOrLink from '@ui/atoms/Button/ButtonOrLink'
import Icon from '@ui/atoms/Icon'
import Typography from '@ui/atoms/Typography'
import Version from '@ui/atoms/Version'
import Section from '@ui/wrappers/Section'
import { useTranslation } from 'react-i18next'

interface Props {
  back?: React.ReactNode
}

export default function Nav({ back }: Props) {
  const { params } = useStepHistory()
  const { i18n } = useTranslation()

  return (
    <Section>
      <nav className="grid h-[var(--nav-height)] grid-cols-3 items-end px-8 py-1">
        <Version />
        {back ?? <div />}
        <ButtonOrLink
          onClick={eventApi.toHome}
          className="relative inset-0 z-10 flex justify-center"
        >
          <img className="h-[35px]" src={`/logo/${i18n.language}.svg`} />
        </ButtonOrLink>
        <ButtonOrLink
          onClick={eventApi.logOut}
          className="flex-0 flex h-8 flex-row justify-end gap-1.5 text-white"
        >
          <Typography variant="PM" className="leading-[1.25rem]">
            {params?.name || ''}
          </Typography>
          <Icon name="logout" className="text-white" />
        </ButtonOrLink>
      </nav>
    </Section>
  )
}
