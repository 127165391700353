import z from 'zod'
import { StrapiVideoConfig } from '.'

export const checkStepResponse = z.object({
  id: z.number(),
  name: z.string(),
  locale: z.string(),
})

export type CheckStepResponseType = z.infer<typeof checkStepResponse>

export enum VerifyVideoState {
  unassigned = 'unassigned',
  draft = 'draft',
  new = 'new',
  outdated = 'outdated',
  updated = 'updated',
  mismatched = 'mismatched',
  processing = 'processing',
  deleted = 'deleted',
}

const baseCheckResponse = z.object({
  ids: z.array(z.number()),
  elaiVersion: z.number().nullable().optional(),
  url: z.string().nullable(),
  state: z.nativeEnum(VerifyVideoState),
  step: checkStepResponse,
  steps: z.array(checkStepResponse),
})

const elaiBaseResponse = z.object({
  _id: z.string(),
  version: z.number(),
  name: z.string(),
  url: z.string().nullable(),
  language: z.string().nullable(),
})

const skipCheckResponse = baseCheckResponse.extend({
  result: z.literal('skip'),
  data: elaiBaseResponse,
  vimeoId: z.null().optional(),
})

export const createCheckResponse = baseCheckResponse.extend({
  result: z.literal('create'),
  vimeoId: z.null().optional(),
  data: elaiBaseResponse,
})

const updateCheckResponse = baseCheckResponse.extend({
  result: z.literal('update'),
  vimeoId: z.string(),
  data: elaiBaseResponse,
})

export const updateCheckResultSchema = z.union([
  skipCheckResponse,
  createCheckResponse,
  updateCheckResponse,
])

export const VerifyVideoSchema = updateCheckResultSchema

export type VerifyVideoType = z.infer<typeof VerifyVideoSchema>

export type VideoConflictError = {
  elaiId: string
  videos: StrapiVideoConfig[]
  message: string
  urls: string[]
}

export type VideoConflictResponse = {
  error: 'video_url_conflict'
  message: string
  context: VideoConflictError[]
}
