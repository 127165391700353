import { ConditionDictionary, ConditionValues } from '@type/conditions'
import { VideoConfig } from '@type/config'
import validateCondition from './validateCondition'

export const filterVideos = (
  videos: VideoConfig[],
  values: ConditionValues,
  conditions: ConditionDictionary
) => {
  return videos.filter((video) => {
    if (!video.condition) {
      return true
    }
    return validateCondition(video.condition, values, conditions).result
  })
}

const matchVideo = (options: string[], videos: VideoConfig[]) => {
  if (!videos || !videos.length) {
    return null
  }

  let defaultVideo: VideoConfig | null = null

  const video = videos.find((video) => {
    if (!video.every) {
      if (!defaultVideo) {
        defaultVideo = video
      }
      return false
    }
    if (video.every.length !== options.length) {
      return false
    }
    return options.every((option) => {
      return video.every?.includes(option)
    })
  })

  if (video) {
    return video
  }

  return defaultVideo
}

export default matchVideo
