import { cva } from 'class-variance-authority'

export const sPaper = cva(
  'relative inset-0 flex w-full rounded-[20px] transition-all',
  {
    variants: {
      intent: {
        basic: 'bg-white',
        blur: [
          'bg-white/50',
          'hover:bg-white/80',
          'active:bg-white',
          'backdrop-blur-lg',
          'transition-colors',
        ],
      },
      shadow: {
        paper: 'shadow-paper',
        active: 'shadow-active',
        none: 'shadow-none',
      },
    },
    defaultVariants: {
      intent: 'basic',
      shadow: 'paper',
    },
  }
)
