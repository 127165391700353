import { atom } from 'jotai'
import { SafeParseReturnType } from 'zod'

import { InitialParams } from '@type/initialParams'

export const initialParamsAtom = atom<SafeParseReturnType<
  InitialParams,
  InitialParams
> | null>(null)

export const paramsAtom = atom<InitialParams | null>(null)
