import { twMerge } from 'tailwind-merge'
import Typography from '@ui/atoms/Typography'
import useCurrencyFormat from '@hooks/useCurrencyFormat'

interface Props {
  plus?: boolean
  price?: number
  priceText?: string
  className?: string
}

export default function Price({ plus, price, className, priceText }: Props) {
  const currency = useCurrencyFormat()

  if (typeof price !== 'number') return null

  return (
    <Typography
      variant="H4"
      component="span"
      className={twMerge('whitespace-nowrap', className)}
    >
      {priceText ?? `${plus ? '+' : ''} ${currency(price)}`}
    </Typography>
  )
}
