type MatchConfig = Record<string, string[]>

type VariableConditions = {
  match: MatchConfig
  value: string | boolean | number | null
}

export type VariableConfig = {
  variable: string
  combinations: VariableConditions[]
  scope: 'global' | 'order'
}

export type ResponseVariablesConfig = VariableConfig[]

const matchConfigValue = (
  config: VariableConditions,
  values: Record<string, string>
) => {
  const keys = Object.keys(config.match)

  const isMatch = keys.every((key) => {
    const match = config.match[key]
    if (!match) {
      return false
    }
    const value = values[key]
    if (!value) {
      return false
    }
    return match.includes(value)
  })

  if (isMatch) {
    return true
  }
  return false
}

const findMatch = (
  configs: VariableConditions[],
  values: Record<string, string>
) => {
  const result = configs.find((config) => {
    return matchConfigValue(config, values)
  })

  if (result) {
    return result.value
  }
  return undefined
}

const getResponseVariables = (
  configs: ResponseVariablesConfig,
  values: Record<string, any>
) => {
  const result: Record<string, string | boolean | number | null> = {}

  configs.forEach((config) => {
    const variableConfigs = config.combinations
    if (!variableConfigs) {
      return
    }

    const matchedValue = findMatch(variableConfigs, values)

    // console.log('variableConfigs', variableConfigs)
    // console.log('values', values)
    // console.log('matchedValue', matchedValue)

    if (matchedValue != null) {
      result[config.variable] = matchedValue
      return
    }

    // For null we want to skip the key
    if (matchedValue === null) {
      return
    }
  })

  return result
}

export default getResponseVariables
