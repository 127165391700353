import { cva } from 'class-variance-authority'

export const sOuter = cva([
  'my-0',
  'mx-auto',
  'box-border',
  'w-full',
  'max-w-[var(--max-width)]',
  'flex',
  'justify-center',
])

export const sInner = cva(['box-border', 'w-full', 'max-w-[var(--max-width)]'])
