import './i18n'
import Jotai from '@jotai/Provider'
import AppRouter from './AppRouter'
import useSetInitialParams from 'hooks/useSetInitialParams'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { useMemo } from 'react'
import { useParamsUpdate } from '@hooks/useParams'
import { TooltipProvider } from '@ui/generated/ui/tooltip'
import { Slide, ToastContainer } from 'react-toastify'

const InitialParams = () => {
  useSetInitialParams()
  useParamsUpdate()
  return null
}

function App() {
  const queryClient = useMemo(() => new QueryClient(), [])
  return (
    <QueryClientProvider client={queryClient}>
      <TooltipProvider>
        <Jotai>
          <InitialParams />
          <AppRouter />
          <ToastContainer
            pauseOnHover={true}
            newestOnTop={true}
            hideProgressBar={true}
            autoClose={6000}
            transition={Slide}
            position="bottom-right"
            className="bottom-8 right-0 w-full max-w-[calc(100vw_-_1rem)] p-0 sm:bottom-12 sm:right-0 sm:w-[336px]"
            draggable={false}
          />
        </Jotai>
      </TooltipProvider>
    </QueryClientProvider>
  )
}

export default App
