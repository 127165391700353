import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import AppConfig from 'AppConfig'
import { Config } from '@type/config'
import { useParams } from 'react-router'
import { useLocaleFormUrl } from '@hooks/useSetInitialParams'

type UseConfigOptions = {
  /**
   * Set preview to fetch draft steps
   */
  publicationState?: 'live' | 'preview'
}

const useConfig = (options?: UseConfigOptions) => {
  const params = useParams()
  const dev = AppConfig.isDev(params)
  const locale = useLocaleFormUrl()

  const publicationState = options?.publicationState || dev ? 'preview' : 'live'
  return useQuery({
    queryKey: ['config', locale, publicationState],
    queryFn: async () => {
      const res = await axios.get<Config>(
        `${AppConfig.strapiUrl}/api/cwa-app-config`,
        {
          params: {
            publicationState,
            locale,
          },
        }
      )

      // if (AppConfig.dev) console.log('Using config', res.data)

      return res.data
    },
    /**
     * useQuery default is 0
     *
     * 5 minutes if not dev
     * 1 second if dev (this is to make development easier, but prevents too many requests)
     */
    staleTime: dev ? 1000 : 60 * 1000 * 5,
  })
}

export default useConfig
