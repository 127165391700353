import { toast as toastify, ToastOptions } from 'react-toastify';
import ToastComponent from './ToastComponent';
import { Content } from './types';

export default function toast(content: Content, options?: ToastOptions) {
  if (typeof content !== 'string')
    return toastify(
      <ToastComponent type={options?.type} {...content} />,
      options
    );

  return toastify(
    <ToastComponent type={options?.type} title={content} />,
    options
  );
}
