export const getCurrencyFormatter = (locale: string, currency: string) => {
  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
    useGrouping: false,
    // @ts-expect-error This is missing from ts definition
    trailingZeroDisplay: 'stripIfInteger',
  })
}

export const currencyFormat = (
  value: number,
  { locale = 'de', currency = 'EUR' }
) => {
  const formatter = getCurrencyFormatter(locale, currency)
  return formatter.format(value)
}
