import useStepHistory from '@hooks/useStepHistory'
import Nav from '@ui/organisms/Nav'
import DevLayout from '@ui/wrappers/DevLayout'

interface Props {
  children: React.ReactNode
  back?: React.ReactNode
}

export default function Layout({ children, back }: Props) {
  const { dev } = useStepHistory()

  return (
    <main className="relative inset-0 h-full min-h-screen w-full">
      <Nav back={back} />
      {dev && <DevLayout />}
      {children}
    </main>
  )
}
