import toast from '@ui/organisms/Toast'
import { ValidationResult } from '@utils/validateCondition'
import { useCallback, useId } from 'react'
import { useTranslation } from 'react-i18next'
import { useConfirm } from './useConfirm'

const useOptionClick = ({
  onClick,
  locked,
  title,
  confirm,
}: {
  onClick?: (e: any) => void
  locked?: ValidationResult
  title: string
  confirm: boolean
}) => {
  const { t } = useTranslation()

  const { open: openConfirm } = useConfirm()

  const id = useId()

  const handleClick = useCallback(
    (e: any) => {
      if (locked?.result) {
        toast(
          {
            title: locked.errorKey ? t(`errors.${locked.errorKey}`) : 'Error',
          },
          { type: 'error', toastId: `${id}-${locked.errorKey}` }
        )
        return
      }

      if (confirm) {
        openConfirm({
          title,
          onConfirm: () => {
            onClick?.(e)
          },
        })
        return
      }

      onClick?.(e)
    },
    [onClick, locked?.result, title, confirm]
  )
  return handleClick
}

export default useOptionClick
