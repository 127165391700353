import { useEffect } from 'react'
import { useNavigate } from 'react-router'
import { useAtom } from 'jotai'
import { authAtom } from '@jotai/auth'
import { endpoints } from '@cwa/types'
import apiClient from '@utils/apiClient'

const useAuth = () => {
  const [{ valid }, setAuthAtom] = useAtom(authAtom)
  const navigate = useNavigate()

  useEffect(() => {
    if (valid) return
    const savedToken = localStorage.getItem('token')
    if (savedToken) validate(savedToken)
    if (!savedToken) setAuthAtom({ valid: false, token: null })
  }, [])

  const validate = async (token: string, errorCallback?: any) => {
    try {
      const res = await apiClient.post<{ valid: boolean }>(endpoints.auth, {
        token,
      })

      if (res.data.valid) {
        localStorage.setItem('token', token)
        setAuthAtom({
          valid: true,
          token,
        })
        errorCallback && errorCallback(false)
      }
    } catch (error) {
      setAuthAtom({
        valid: false,
        token: null,
      })
      errorCallback && errorCallback(true)
    }
  }

  const logout = () => {
    localStorage.removeItem('token')
    setAuthAtom({
      valid: null,
      token: null,
    })
    navigate('/auth')
  }

  return { isValid: valid, validate, logout }
}

export default useAuth
