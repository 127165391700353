import { cva } from 'class-variance-authority';
import { IconNameProp } from '@ui/atoms/Icon/types';
import type { TypeOptions } from 'react-toastify';

export const sToast = cva(
  [
    'mt-4 min-h-[3.5rem] gap-4 p-4 pb-[1.125rem] pl-5 pr-8',
    'flex w-full items-center rounded-l-xl rounded-r-none',
  ],
  {
    variants: {
      type: {
        default: [],
        success: [],
        info: [],
        warning: [],
        error: [],
      },
      intent: {
        filled: ['shadow-xl', 'border'],
        outline: ['shadow-lg', 'border'],
      },
    },
    compoundVariants: [
      {
        type: 'default',
        intent: 'filled',
        className: [
          'bg-gradient-to-br from-gray-900 to-gray-950',
          'text-white',
          'shadow-xl shadow-gray-950/25',
          'border-none',
        ],
      },
      {
        type: 'default',
        intent: 'outline',
        className: [
          'bg-white',
          'text-gray-700',
          'shadow-gray-500/5',
          'border-gray-100',
        ],
      },
      {
        type: 'success',
        intent: 'filled',
        className: [
          'bg-gradient-to-br from-main-300 to-main-600',
          'text-white',
          'shadow-xl shadow-main-600/25',
          'border-none',
        ],
      },
      {
        type: 'success',
        intent: 'outline',
        className: [
          'bg-main-50',
          'text-main-500',
          'shadow-main-500/10',
          'border-main-500/25',
        ],
      },
      {
        type: 'info',
        intent: 'filled',
        className: [
          'bg-gradient-to-br from-blue-400 to-blue-700',
          'text-white',
          'shadow-xl shadow-blue-700/25',
          'border-none',
        ],
      },
      {
        type: 'info',
        intent: 'outline',
        className: [
          'bg-blue-50',
          'text-blue-500',
          'shadow-blue-500/10',
          'border-blue-500/25',
        ],
      },
      {
        type: 'warning',
        intent: 'filled',
        className: [
          'bg-gradient-to-br from-yellow-200 to-orange-400',
          'text-white',
          'shadow-xl shadow-orange-400/25',
          'border-none',
        ],
      },
      {
        type: 'warning',
        intent: 'outline',
        className: [
          'bg-[#F9F1DF]',
          'text-yellow-300',
          'shadow-yellow-300/10',
          'border-yellow-300/25',
        ],
      },
      {
        type: 'error',
        intent: 'filled',
        className: [
          'bg-gradient-to-br from-red-500 to-red-800',
          'text-white',
          'shadow-xl shadow-red-800/25',
          'border-none',
        ],
      },
      {
        type: 'error',
        intent: 'outline',
        className: [
          'bg-red-50',
          'text-red-600',
          'shadow-red-600/10',
          'border-red-600/25',
        ],
      },
    ],
    defaultVariants: {
      type: 'default',
      intent: 'outline',
    },
  }
);

export const iconsMap: Record<TypeOptions, IconNameProp> = {
  default: 'check',
  success: 'circle-check-filled',
  info: 'info-square-filled',
  warning: 'alert-triangle-filled',
  error: 'alert-circle-filled',
};
