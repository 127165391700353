import { StepHistory } from 'types/StepHistory'

export type PriceOptionValue = {
  value: string
  price: number
  label: string
}

export type OptionValue = {
  value: string
  label?: string
  price?: number
}

export interface ParamValue extends OptionValue {
  param: string
}

export interface PriceParamValue extends PriceOptionValue {
  param: string
}

export type OptionValues = Record<string, OptionValue>

export const findOrderIndex = (history: StepHistory, order?: number) => {
  return history.findIndex(
    ({ step, value }) => step.variable === 'order' && Number(value) === order
  )
}

const findFirstOrderIndex = (history: StepHistory) => {
  return history.findIndex(
    ({ step, value }) => step.variable === 'order' && Number(value) >= 1
  )
}

/**
 * Returns history for given order number or whole history if no order is set
 */
export const getOrderHistory = (history: StepHistory, order?: number) => {
  // No order set take whole history
  if (!order) {
    return history
  }

  const orderStart = findOrderIndex(history, order)
  /**
   * Any history step including a numeric order value
   */
  const commonEnd = findFirstOrderIndex(history)

  // Still in common part of history
  if (!orderStart && !commonEnd) {
    return history
  }
  const commonHistory = history.slice(0, commonEnd)

  // Order did not start yet
  if (!orderStart) {
    return commonHistory
  }

  const orderEnd = findOrderIndex(history, order + 1)

  // Order did not end yet take all common and history from order start
  if (orderEnd === -1) {
    return [...commonHistory, ...history.slice(orderStart)]
  }

  // Return common and order history
  return [...commonHistory, ...history.slice(orderStart, orderEnd)]
}

export const getValuesFromHistory = (
  history: StepHistory,
  onlyOutput?: boolean,
  order?: number
) => {
  let params: OptionValues = {}

  const orderHistory = getOrderHistory(history, order)

  orderHistory.forEach(
    ({ step: { output, variable }, value, label, price, overrides }) => {
      const include = !onlyOutput ? true : output
      if (include && variable && value != null) {
        params[variable] = {
          value,
          price,
          label,
        }
      }
      if (overrides) {
        Object.entries(overrides).forEach(([key, override]) => {
          params[key] = {
            ...params[key],
            ...override,
          }
        })
      }
    }
  )

  return params
}

export const getValuesListFromHistory = (
  history: StepHistory,
  onlyOutput?: boolean
) => {
  const valuesList: ParamValue[] = []
  history.forEach(
    ({ step: { output, variable }, value, label, price, overrides }) => {
      const include = !onlyOutput ? true : output
      if (include && variable && value != null) {
        valuesList.push({
          value,
          price,
          label,
          param: variable,
        })
      }
      if (overrides) {
        Object.entries(overrides).forEach(([key, override]) => {
          valuesList.push({
            param: key,
            ...override,
          })
        })
      }
    }
  )

  return valuesList
}

export const getFlatOptionsValues = (values: OptionValues) => {
  return Object.entries(values).reduce((acc, [key, { value }]) => {
    return {
      ...acc,
      [key]: value,
    }
  }, {} as Record<string, string>)
}

const optionEntryIsWithPrice = (
  entry: [string, OptionValue]
): entry is [string, PriceOptionValue] => entry[1].price != null

const paramValueIsWithPrice = (item: ParamValue): item is PriceParamValue =>
  item.price != null

export const getSummary = (values: OptionValues, withPrice = true) => {
  const entries = Object.entries(values)

  const filteredEntries = withPrice
    ? entries.filter(optionEntryIsWithPrice)
    : entries

  const selected = filteredEntries.map(([key, o]) => ({
    attribute: key,
    value: o.value,
    label: o.label,
    price: o.price || 0,
  }))

  return {
    selected,
    total: selected.reduce((acc, { price }) => acc + price, 0),
  }
}

export const getSummaryFromParamValues = (
  values: ParamValue[],
  withPrice = true
) => {
  const filteredEntries = withPrice
    ? values.filter(paramValueIsWithPrice)
    : values

  const selected = filteredEntries.map((o) => ({
    attribute: o.param,
    value: o.value,
    label: o.label,
    price: o.price || 0,
  }))

  return {
    selected,
    total: selected.reduce((acc, { price }) => acc + price, 0),
  }
}
