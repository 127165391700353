import { z } from 'zod'

const literalSchema = z.union([z.string(), z.number(), z.boolean(), z.null()])
type Literal = z.infer<typeof literalSchema>

type Json = Literal | { [key: string]: Json } | Json[]

export const jsonSchema: z.ZodType<Json> = z.lazy(() =>
  z.union([literalSchema, z.array(jsonSchema), z.record(jsonSchema)])
)

export const jsonObjectSchema = z.record(jsonSchema)

export type JSONObjectType = z.infer<typeof jsonObjectSchema>

export type JSONType = z.infer<typeof jsonSchema>
