import { Navigate, RouterProvider } from 'react-router'
import { createBrowserRouter } from 'react-router-dom'

import RouteErrorBoundary from '@ui/errors/RouteErrorBoundary'
import RouteNotFound from '@ui/errors/RouteNotFound'
import { wrapCreateBrowserRouter } from '@sentry/react'

import HomePage from 'pages/home/page'

const sentryCreateBrowserRouter = wrapCreateBrowserRouter(createBrowserRouter)

const router = sentryCreateBrowserRouter([
  {
    path: '/',
    errorElement: <RouteErrorBoundary />,
    element: <HomePage />,
  },
  {
    path: '/admin',
    errorElement: <RouteErrorBoundary />,
    lazy: () => import('pages/admin/lazy'),
  },
  {
    path: '/admin/dev/params',
    lazy: () => import('pages/dev/params/lazy'),
  },
  {
    path: '/admin/dev/migrate',
    lazy: () => import('pages/dev/migrate/lazy'),
  },
  {
    path: '/admin/matcher',
    lazy: () => import('pages/matcher/lazy'),
  },
  {
    path: '/admin/dev/prepare-locale',
    lazy: () => import('pages/dev/prepare-locale/lazy'),
  },
  {
    path: '/result',
    errorElement: <RouteErrorBoundary />,
    lazy: () => import('pages/result/lazy'),
  },
  {
    path: '/flows',
    errorElement: <RouteErrorBoundary />,
    element: <Navigate to="/admin/visualization" />,
  },
  {
    path: '/admin/visualization',
    errorElement: <RouteErrorBoundary />,
    lazy: () => import('pages/visualization/lazy'),
  },
  {
    path: '/admin/uploader',
    errorElement: <RouteErrorBoundary />,
    lazy: () => import('pages/uploader/lazy'),
  },
  {
    path: '/auth',
    errorElement: <RouteErrorBoundary />,
    lazy: () => import('pages/auth/lazy'),
  },
  {
    path: '/logout',
    errorElement: <RouteErrorBoundary />,
    lazy: () => import('pages/logout/lazy'),
  },
  {
    path: '*',
    element: <RouteNotFound />,
  },
])

const AppRouter = () => <RouterProvider router={router} />

export default AppRouter
