import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import {
  getSummaryFromParamValues,
  getValuesListFromHistory,
} from '@utils/optionUtils'
import Cart from '@ui/organisms/Cart'
import Section from '@ui/wrappers/Section'
import { StepHistory } from '@type/StepHistory'

interface Props {
  history: StepHistory
}

const Summary = ({ history }: Props) => {
  const { total, selected } = useMemo(
    () => getSummaryFromParamValues(getValuesListFromHistory(history), true),
    [history]
  )
  const { t } = useTranslation()

  return (
    <Section>
      <Cart
        totalLabel={t('summary.total')}
        label={t('summary.label')}
        price={total}
        items={selected.map((option) => ({
          label: option.label || option.value,
          price: option.price,
        }))}
      />
    </Section>
  )
}

export default Summary
