import Icon from '@ui/atoms/Icon'
import { twMerge } from 'tailwind-merge'

const sLink =
  'flex flex-row items-center justify-center gap-1 text-blue-400 hover:text-blue-600'

const ExternalLink = ({
  href,
  children,
  className,
}: {
  className?: string
  href: string
  children: React.ReactNode
}) => (
  <a target="_blank" href={href} className={twMerge(sLink, className)}>
    <Icon name="link" size={20} /> {children}
  </a>
)

export default ExternalLink
