import { ImageData } from '@type/config'
import AppConfig from 'AppConfig'
import React from 'react'

type HtmlImgProps = React.DetailedHTMLProps<
  React.ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>

type ImgProps = Omit<HtmlImgProps, 'src'> & {
  image: ImageData
}

const getImageRemoteUrl = (
  src: string,
  options?: {
    width?: number
    height?: number
    quality?: number
  }
) => {
  const { width, height, quality = 85 } = options || {}
  const imgSrc = src.replace(/^\/uploads/, '')
  const params = [
    'f_webp',
    `q_${quality}`,
    width ? `width_${width}` : '',
    height ? `height_${height}` : '',
  ]
    .filter(Boolean)
    .join(',')
  return `${AppConfig.strapiUrl}/uploads/${params}/${imgSrc}`
}

const Img = ({ image, ...rest }: ImgProps) => {
  const url = image?.remote ? getImageRemoteUrl(image.src) : image.src
  return <img {...rest} src={url} />
}

export default Img
