export function timeFromNow(timestamp: Date, locale: string) {
  const now = new Date().getTime()
  const diffMs = now - new Date(timestamp).getTime()
  const diffSeconds = Math.floor(diffMs / 1000)
  const diffMinutes = Math.floor(diffSeconds / 60)
  const diffHours = Math.floor(diffMinutes / 60)
  const diffDays = Math.floor(diffHours / 24)
  const diffWeeks = Math.floor(diffDays / 7)

  const formatter = new Intl.RelativeTimeFormat(locale, { numeric: 'auto' })

  if (diffSeconds < 60) return formatter.format(-diffSeconds, 'second') // up to 59 seconds
  if (diffMinutes < 60) return formatter.format(-diffMinutes, 'minute') // up to 59 minutes
  if (diffHours < 24) return formatter.format(-diffHours, 'hour') // up to 23 hours
  if (diffDays < 14) return formatter.format(-diffDays, 'day') // up to 13 days
  return formatter.format(diffWeeks, 'week')
}
