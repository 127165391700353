import { InitialParams } from '@type/initialParams'

import { merge } from 'lodash'

export type DeepPartial<T> = T extends object
  ? {
      [P in keyof T]?: DeepPartial<T[P]>
    }
  : T

type ActionConfig = {
  callback: (props: {
    params: InitialParams
  }) => DeepPartial<InitialParams> | undefined
}

export const actions = {
  sum_sph_add: {
    callback: ({ params }) => {
      const ref = {
        r_sph: params.ref.r_sph + params.ref.r_add,
        l_sph: params.ref.l_sph + params.ref.l_add,
        l_add: 0,
        r_add: 0,
      }

      return {
        ref,
      }
    },
  },
  reset_add: {
    callback: () => {
      const ref = {
        l_add: 0,
        r_add: 0,
      }

      return {
        ref,
      }
    },
  },
} as const satisfies Record<string, ActionConfig>

export type OptionActionKeys = keyof typeof actions

export const applyOptionAction = (
  params: InitialParams,
  action?: OptionActionKeys
) => {
  if (!action) {
    return params
  }
  const callback = actions[action]?.callback
  if (!callback) {
    return params
  }
  const partial = callback({ params })
  return merge({}, params, partial)
}
