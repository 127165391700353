import Section from '@ui/wrappers/Section'
import Typography from '@ui/atoms/Typography'

interface Props {
  children: React.ReactNode
}

export default function Headline({ children }: Props) {
  return (
    <Section className="mb-4 mt-16 px-24">
      <div className="flex h-24 w-full flex-col items-center justify-end">
        <Typography variant="H1" className="text-center" color="text-white">
          {children}
        </Typography>
      </div>
    </Section>
  )
}
