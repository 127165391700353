import { useEffect, useMemo } from 'react'
import { useAtom } from 'jotai'

import { initialParamsAtom } from '@jotai/config'
import { initialParamsSchema } from '@type/initialParams'
import qs from 'qs'
import { mockParams } from 'mock/params'
import AppConfig from 'AppConfig'

const localeReplacements: Record<string, string> = {
  at: 'de',
}

export const getParsedUrlParams = () => {
  // @deprecated (?)
  // HACK: For now we want to ignore the ?showBack=1 param on refresh
  if (
    window.location.search
    //  && window.location.search !== '?showBack=1'
  ) {
    const query = qs.parse(window.location.search, { ignoreQueryPrefix: true })
    // HACK to match locale to a different one ie: at with de
    if (typeof query.locale === 'string' && localeReplacements[query.locale]) {
      query.locale = localeReplacements[query.locale]
    }

    const isSafe = window.location.pathname.includes('admin')
    // Safe mode will only return the params if they match schema
    const parsed = initialParamsSchema.safeParse(query)

    if (parsed.success) {
      return parsed.data
    }

    if (isSafe) {
      return {
        ...mockParams[AppConfig.initialParamsKey],
        ...query,
      }
    }
  }

  return mockParams[AppConfig.initialParamsKey]
}

export const useLocaleFormUrl = () => {
  const urlLocale = useMemo(() => {
    const params = getParsedUrlParams()
    return params?.locale as string | undefined
  }, [window.location.search])

  if (urlLocale && localeReplacements[urlLocale]) {
    return localeReplacements[urlLocale]
  }
  return urlLocale
}

const useSetInitialParams = () => {
  const [value, setValue] = useAtom(initialParamsAtom)

  const params = useMemo(() => getParsedUrlParams(), [])

  useEffect(() => {
    if (value == null) {
      const parsed = initialParamsSchema.safeParse(params)
      setValue(parsed)
    }
  }, [params])
}

export default useSetInitialParams
