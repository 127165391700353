import ButtonOrLink from '@ui/atoms/Button/ButtonOrLink'
import Icon from '@ui/atoms/Icon'
import Typography from '@ui/atoms/Typography'
import { useTranslation } from 'react-i18next'

interface Props {
  onClick?: () => void
  href?: string
}

export default function Back({ onClick, href }: Props) {
  const { t } = useTranslation()
  return (
    <ButtonOrLink
      href={href}
      onClick={onClick}
      className="flex h-8 animate-fadein items-start gap-1.5 text-white"
    >
      <Icon name="arrow-left" size={24} />
      <Typography variant="PM" className="leading-[1.25rem]">
        {t('back')}
      </Typography>
    </ButtonOrLink>
  )
}
