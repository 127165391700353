import { twMerge } from 'tailwind-merge'

const border = 'border-2 border-dashed border-white/50'
const fixed = 'fixed left-1/2 top-0 -translate-x-1/2'
const pointer = 'pointer-events-none'

export default function DevLayout() {
  return (
    <>
      <div
        className={twMerge(
          'h-[var(--max-height)] w-[var(--max-width)] rounded-[25px]',
          fixed,
          border,
          pointer
        )}
      />
      <div
        className={twMerge(
          'w-[var(--max-width)]',
          fixed,
          'top-[var(--nav-height)]',
          border,
          pointer
        )}
      />
    </>
  )
}
