import { Suspense, useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'

import eventApi from '@api/eventApi'
import useStepHistory from '@hooks/useStepHistory'
import { templatesMap } from '@type/templates'

import Back from '@ui/molecules/Back'
import Layout from '@ui/wrappers/Layout'

import LayoutAdmin from '@components/LayoutAdmin/LayoutAdmin'
import Summary from '@components/Summary/Summary'

export default function Step() {
  const { current, hasBack, back, dev, history } = useStepHistory()
  const [searchParams, setSearchParams] = useSearchParams()

  useEffect(() => {
    const params = new URLSearchParams(searchParams)
    // if (!hasBack) {
    //   params.set('showBack', '1')
    // } else {
    //   params.delete('showBack')
    // }

    setSearchParams(params)
  }, [hasBack])

  if (!current?.step) return null

  const Template = templatesMap[current.step.template]

  if (!Template) {
    console.error(`Template ${current.step.template} not found`)
    return null
  }

  return (
    <LayoutAdmin showLocale hideNavigation={!dev} noAuth>
      <Layout
        back={
          // If hasBack is true, then we use the back function from useStepHistory
          hasBack ? <Back onClick={back} /> : <Back onClick={eventApi.goBack} />
        }
      >
        <Summary history={history} />
        <Suspense fallback={null}>
          <Template key={current?.step.id} />
        </Suspense>
      </Layout>
    </LayoutAdmin>
  )
}
