import * as Sentry from '@sentry/react'
import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App.tsx'
import './index.css'
import AppConfig from './AppConfig.ts'
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router'

const initSentry = () => {
  Sentry.init({
    dsn: 'https://6305dc375d260a73f01b9fc1e5bcaae5@sentry.supervista.net/2',
    integrations: [
      // This needs to be setup on the server as well
      new Sentry.BrowserTracing({
        // Leave this empty for now as this needs to be handled in strapi to avoid CORS issues
        tracePropagationTargets: [],
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          React.useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes
        ),
      }),
      new Sentry.Replay(),
    ],
    environment: AppConfig.environment,
    tracesSampleRate: AppConfig.sentry.tracesSampleRate, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: AppConfig.sentry.replaysSessionSampleRate, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: AppConfig.sentry.replaysOnErrorSampleRate, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  })
}

if (AppConfig.environment !== 'local') {
  initSentry()
}

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
)
