import { Link } from 'react-router-dom';
import { ButtonOrLinkProps } from './types';

export default function ButtonOrLink({
  children,
  href,
  ref,
  ...props
}: ButtonOrLinkProps) {
  const isLink = typeof href !== 'undefined';
  if (!isLink)
    return (
      <button type="button" ref={ref} {...props}>
        {children}
      </button>
    );

  const isExternal = href.includes('https://');
  if (isExternal)
    return (
      <a href={href} target="_blank" rel="noreferrer" ref={ref} {...props}>
        {children}
      </a>
    );

  // Had to extract ref from props and not pass it to Link
  return (
    <Link to={href} {...props}>
      {children}
    </Link>
  );
}
