import { useTranslation } from 'react-i18next'
import AppConfig from 'AppConfig'
import useStepHistory from '@hooks/useStepHistory'
import { timeFromNow } from '@utils/timing'
import { twMerge } from 'tailwind-merge'

const T = ({ children, dim }: { children: React.ReactNode; dim?: boolean }) => (
  <div
    className={twMerge('select-text whitespace-nowrap', dim && 'opacity-50')}
  >
    {children}
  </div>
)

const O = () => <T dim>&bull;</T>

export default function Version({ className }: { className?: string }) {
  const { i18n } = useTranslation()
  const { dev } = useStepHistory()
  if (!dev) return null

  return (
    <div
      className={twMerge(
        'absolute left-1/2 top-0 z-[100] flex -translate-x-1/2 select-text gap-2 p-2 text-sm font-bold text-white',
        className
      )}
    >
      <T>
        {AppConfig.appName} {AppConfig.appVersion}
      </T>
      <O />
      <T dim>
        {new Date(AppConfig.appBuild)
          .toLocaleString(i18n.language, { timeZone: 'Europe/Berlin' })
          .slice(0, 16)
          .replace('T', ' ')}
      </T>
      <O />
      <T dim>{timeFromNow(new Date(AppConfig.appBuild), i18n.language)}</T>
      <O />
      <T>
        {
          AppConfig.strapiUrl
            .replace('https://', '')
            .replace('http://', '')
            .split('.')[0]
        }
      </T>
    </div>
  )
}
