const eventApi = {
  postResult: (payload: any) => {
    // @ts-expect-error
    return window.api.sendEvent({
      name: 'configure-order',
      extra: {
        event: 'finish-order',
        data: payload,
      },
    })
  },
  goBack: () => {
    try {
      // @ts-expect-error
      return window.api.sendEvent({
        name: 'configure-order',
        extra: {
          event: 'go-back',
        },
      })
    } catch (ex) {
      console.error('Error sending go-back event', ex)
    }
  },
  logOut: () => {
    try {
      // @ts-expect-error
      return window.api.sendEvent({
        name: 'configure-order',
        extra: {
          event: 'check-out',
        },
      })
    } catch (ex) {
      console.error('Error sending logo out event', ex)
    }
  },
  toHome: () => {
    try {
      // @ts-expect-error
      return window.api.sendEvent({
        name: 'configure-order',
        extra: {
          event: 'to-home',
        },
      })
    } catch (ex) {
      console.error('Error sending to home event', ex)
    }
  },
}

export default eventApi
