import * as React from 'react'
import { Dialog } from '@headlessui/react'
import { twMerge } from 'tailwind-merge'
import { VariantProps, cva } from 'class-variance-authority'
import Typography from '@ui/atoms/Typography'
import { ScrollArea } from '@ui/generated/ui/scroll-area'

type ModalProps = {
  open: boolean
  onClose: () => void
  title?: React.ReactNode
  description?: React.ReactNode
  children?: React.ReactNode
  className?: string
}

const textStyle = cva('leading-none', {
  variants: {
    variant: {
      info: 'text-gray-600',
      success: 'text-green-600',
      error: 'text-red-600',
    },
  },
})

type TextStyleProps = VariantProps<typeof textStyle>

export const Modal = ({
  open,
  onClose,
  title,
  description,
  children,
  variant = 'info',
  className,
}: ModalProps & TextStyleProps) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      as="div"
      className={twMerge(
        'fixed inset-0 z-[200] flex items-center justify-center overflow-y-auto',
        open && 'bg-black/75'
      )}
    >
      <Dialog.Overlay />
      <Dialog.Panel
        className={twMerge(
          'relative flex max-h-full w-[480px] flex-col gap-8 overflow-auto rounded-xl bg-white p-2 text-center text-gray-800',
          className
        )}
      >
        <ScrollArea className="w-full p-4 ">
          <div>
            <div className="flex flex-col gap-4">
              {title && (
                <Dialog.Title
                  as={Typography}
                  variant="H4"
                  className={textStyle({ variant })}
                >
                  {title}
                </Dialog.Title>
              )}
              {description && (
                <Dialog.Description as={Typography} className=" font-normal">
                  {description}
                </Dialog.Description>
              )}
            </div>
            <div className="flex flex-col gap-4">{children}</div>
          </div>
        </ScrollArea>
      </Dialog.Panel>
    </Dialog>
  )
}

export default Modal
