import { getStrapiStepUrl } from '@utils/urls'
import { StepConfig } from '@type/config'
import ExternalLink from '@/pages/matcher/ExternalLink'
import Info from '@ui/atoms/Info'

const StepInfo = ({ step }: { step: StepConfig }) => (
  <Info>
    <ExternalLink
      className="text-magenta-800 hover:text-magenta-500"
      href={getStrapiStepUrl(step)}
    >
      {step.name}
    </ExternalLink>
  </Info>
)

export default StepInfo
