import { cva } from 'class-variance-authority'

export const sButton = cva(
  [
    'z-1 relative inset-0',
    'flex shrink-0 grow-0 items-start justify-center',
    'box-border rounded-[100px]',
    'font-head font-bold',
    'outline-none transition-all duration-300 ease-in-out',
  ],
  {
    variants: {
      intent: {
        primary: [
          'shadow-button',
          'bg-magenta-500 text-white',
          'hover:bg-magenta-300',
          'focus-visible:bg-magenta-300',
          'active:bg-white active:text-magenta-500',
          '',
          'group-hover:bg-magenta-300',
          'group-focus-visible:bg-magenta-300',
          'group-active:bg-white group-active:text-magenta-500',
        ],
        secondary: [
          // 'shadow-button',
          'border-2 border-magenta-500',
          'bg-white text-magenta-500',
          'hover:bg-magenta-300 hover:text-white',
          'focus-visible:bg-magenta-300 focus-visible:text-white',
          'active:bg-magenta-500 active:text-white',
          '',
          'group-hover:bg-magenta-300 group-hover:text-white',
          'group-focus-visible:bg-magenta-300 group-focus-visible:text-white',
          'group-active:bg-magenta-500 group-active:text-white',
          '',
          'disabled:border-gray-100',
        ],
        negative: [
          'shadow-outline',
          'bg-transparent text-white',
          'hover:text-gray-50',
          'focus-visible:text-gray-75',
          'active:text-gray-100',
          '',
          'group-hover:text-gray-50',
          'group-focus-visible:text-gray-75',
          'group-active:text-gray-100',
        ],
      },
      disabled: {
        true: [
          'shadow-none',
          'bg-gray-100 text-gray-50',
          'pointer-events-none cursor-not-allowed',
          'border-gray-100',
          'group-hover:bg-gray-100 group-hover:text-gray-50',
          'group-focus-visible:bg-gray-100 group-focus-visible:text-gray-50',
          'group-active:bg-gray-100 group-active:text-gray-50',
        ],
        false: '',
      },
      width: {
        minimal: 'min-w-[15rem] max-w-max',
        content: 'w-max min-w-min max-w-max',
        full: 'w-full',
      },
      size: {
        large: ['text-[2rem]', 'h-[3.75rem]', 'px-8 pb-0 pt-1', 'leading-snug'],
        small: ['text-[1.5rem]', 'h-[3rem]', 'px-4 pb-1 pt-0', 'leading-none'],
      },
    },
    compoundVariants: [
      {
        size: 'small',
        width: 'minimal',
        className: 'min-w-[8rem] max-w-max items-center',
      },
    ],
    defaultVariants: {
      intent: 'primary',
      disabled: false,
      width: 'minimal',
      size: 'large',
    },
  }
)
