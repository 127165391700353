const getModifier = (modifier: string) => {
  if (modifier?.toLowerCase() === 'photolite') {
    return 'f'
  }
  if (modifier?.toLowerCase() === 'polarlite') {
    return 'n'
  }

  return ''
}

const getLens = ({
  lensPrefix,
  index,
  coating,
  modifier = '',
}: Record<string, string>) =>
  `${lensPrefix}-${index}${getModifier(modifier)}-${coating}`.toUpperCase()

export default getLens
