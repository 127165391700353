export const mockSpParams = {
  store_ref: '022001',
  customer_ref: 'DE-3D2HG',
  type: 'SP',
  birthday: '1960-01-01',
  locale: 'de',
  ref: {
    r_sph: '1.00',
    l_sph: '1.00',
    r_cyl: '0.00',
    l_cyl: '0.00',
    r_axis: '10',
    l_axis: '10',
    prism: true,
    right_na_te: 'in',
    left_na_te: 'out',
    right_ha_ba: 'up',
    left_ha_ba: 'up',
    r_prism1: '2',
    l_prism1: '3',
    r_prism2: '2',
    l_prism2: '4',
    r_add: '1.50',
    l_add: '1.50',
    r_pd: '30.0',
    l_pd: '30.0',
  },
  orders: [
    {
      session_id: '123',
      frame: '7A467-C4',
      frame_type: 'FULL',
      frame_price: 80.0,
      r_fh: 22.0,
      l_fh: 22.0,
      vbox: 36.5,
      y_ext: 0.0,
    },
  ],
}

export const mockDpParams = {
  store_ref: '022001',
  customer_ref: 'DE-3D2HG',
  type: 'DP',
  birthday: '1960-01-01',
  locale: 'de',
  ref: {
    r_sph: '1.00',
    l_sph: '1.00',
    r_cyl: '0.00',
    l_cyl: '0.00',
    r_axis: '10',
    l_axis: '10',
    prism: true,
    right_na_te: 'in',
    left_na_te: 'out',
    right_ha_ba: 'up',
    left_ha_ba: 'up',
    r_prism1: '2',
    l_prism1: '3',
    r_prism2: '2',
    l_prism2: '4',
    r_add: '1.50',
    l_add: '1.50',
    r_pd: '30.0',
    l_pd: '30.0',
  },
  orders: [
    {
      session_id: '123',
      frame: '7A467-C4',
      frame_type: 'FULL',
      r_fh: 22.0,
      l_fh: 22.0,
      frame_price: 80.0,
      vbox: 36.5,
      y_ext: 0.0,
    },
    {
      session_id: '124',
      frame: 'TR170-C2',
      frame_type: 'FULL',
      r_fh: 22,
      l_fh: 22.0,
      vbox: 36.5,
      frame_price: 20.0,
      y_ext: 0.0,
    },
  ],
}

export const mockSommerParams = {
  strategy_input: '2PS1',
  store_ref: '022001',
  customer_ref: 'DE-3D2HG',
  type: 'DP',
  birthday: '1960-01-01',
  locale: 'de',
  ref: {
    r_sph: '1.00',
    l_sph: '1.00',
    r_cyl: '0.00',
    l_cyl: '0.00',
    r_axis: '10',
    l_axis: '10',
    prism: true,
    right_na_te: 'in',
    left_na_te: 'out',
    right_ha_ba: 'up',
    left_ha_ba: 'up',
    r_prism1: '2',
    l_prism1: '3',
    r_prism2: '2',
    l_prism2: '4',
    r_add: '1.50',
    l_add: '1.50',
    r_pd: '30.0',
    l_pd: '30.0',
  },
  orders: [
    {
      session_id: '123',
      frame: '7A467-C4',
      frame_type: 'FULL',
      r_fh: 22.0,
      l_fh: 22.0,
      frame_price: 80.0,
    },
    {
      session_id: '124',
      frame: 'TR170-C2',
      frame_type: 'FULL',
      r_fh: 22,
      l_fh: 22.0,
      frame_price: 20.0,
    },
  ],
}

export const mockOct3F2Params = {
  strategy_input: 'SVF',
  store_ref: '022001',
  customer_ref: 'DE-3D2HG',
  type: 'DP',
  birthday: '1960-01-01',
  locale: 'de',
  strategy: 'sv',
  ref: {
    r_sph: '1.00',
    l_sph: '1.00',
    r_cyl: '0.00',
    l_cyl: '0.00',
    r_axis: '10',
    l_axis: '10',
    prism: true,
    right_na_te: 'in',
    left_na_te: 'out',
    right_ha_ba: 'up',
    left_ha_ba: 'up',
    r_prism1: '2',
    l_prism1: '3',
    r_prism2: '2',
    l_prism2: '4',
    r_add: '1.50',
    l_add: '1.50',
    r_pd: '30.0',
    l_pd: '30.0',
  },
  orders: [
    {
      session_id: '123',
      frame: '7A467-C4',
      frame_type: 'FULL',
      r_fh: 22.0,
      l_fh: 22.0,
      vbox: 36.5,
      y_ext: 0.0,
      frame_price: 80.0,
    },
    {
      session_id: '124',
      frame: 'TR170-C2',
      frame_type: 'FULL',
      frame_price: 20.0,
      r_fh: 22,
      l_fh: 22.0,
      vbox: 36.5,
      y_ext: 0.0,
    },
  ],
}

export const mockParams = {
  dp: mockDpParams,
  sp: mockSpParams,
  sommer: mockSommerParams,
  oct3f2: mockOct3F2Params,
}
