import { compile } from 'path-to-regexp'

export type PathParams<T extends { get: (params: any) => string }> = Parameters<
  T['get']
>[0]

const paramPath = <T extends object>(path: string) => {
  const toPath = compile(path, { encode: encodeURIComponent })

  return {
    /**
     * Compile path with params
     */
    get: (params: T): string => toPath(params),
    /**
     * Plain path without params
     */
    path,
  }
}

export default paramPath
