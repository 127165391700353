import { twMerge } from 'tailwind-merge'
import icons from './icons'
import { IconProps } from './types'

export default function Icon({
  name,
  size = 24,
  stroke = 2,
  color,
  className,
}: IconProps) {
  const Element = icons[name]
  if (!Element) {
    console.error(`Icon ${name} not found`)
    return null
  }
  return (
    <Element
      size={size}
      stroke={stroke}
      className={twMerge('flex flex-shrink-0 flex-grow-0', className, color)}
    />
  )
}
