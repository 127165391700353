import { twMerge } from 'tailwind-merge'

import { OptionComponentProps } from '@type/components'
import ButtonOrLink from '@ui/atoms/Button/ButtonOrLink'
import Paper from '@ui/atoms/Paper'
import Typography from '@ui/atoms/Typography'
import Button from '@ui/atoms/Button'
import Img from '@ui/atoms/Img'
import useOptionClick from '@hooks/useOptionClick'

type Props = OptionComponentProps & {
  classNameImageWrapper?: string
}

export default function OptionFlow({
  title,
  description,
  images = [],
  cta,
  onClick,
  href,
  disabled,
  locked,
  confirm,
  ...props
}: Props) {
  const image = images[0]

  const handleClick = useOptionClick({ onClick, locked, title, confirm })

  return (
    <ButtonOrLink
      onClick={handleClick}
      href={href}
      disabled={disabled}
      className={twMerge(
        'group w-full',
        disabled && 'pointer-events-none opacity-75'
      )}
    >
      <Paper
        className="flex h-full min-h-[14rem] flex-col overflow-hidden"
        shadow={disabled ? 'none' : 'paper'}
      >
        {disabled && (
          <div className="absolute left-0 top-0 z-10 h-full w-full bg-white/75" />
        )}
        <Typography
          variant="H3"
          className="relative inset-0 z-[1] p-6 pb-0 font-bold leading-none tracking-tighter"
        >
          {title}
        </Typography>
        <div className="flex h-full min-w-[64%] max-w-[64%] items-end justify-between">
          <div className="flex h-full flex-1 flex-grow flex-col items-start justify-between gap-4 p-6 pt-4">
            <div className="flex flex-col items-start gap-2">
              {description && (
                <Typography
                  className="font-text font-medium leading-tight"
                  color="text-gray-900/75"
                >
                  {description}
                </Typography>
              )}
            </div>
            <Button
              intent="secondary"
              text={cta}
              disabled={disabled}
              className="relative inset-0 z-20 items-center px-8 lowercase"
              width="content"
              size="small"
            />
          </div>

          <div
            className={twMerge(
              'absolute bottom-0 right-0 z-[0] flex min-w-[39%] max-w-[39%] flex-grow-0',
              props.classNameImageWrapper
            )}
          >
            {image && (
              <Img
                image={image}
                alt={title}
                className="w-full animate-[fadein_1s_ease-out] object-contain object-bottom"
              />
            )}
          </div>
        </div>
      </Paper>
    </ButtonOrLink>
  )
}
