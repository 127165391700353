import { twMerge } from 'tailwind-merge'
import { Popover } from '@headlessui/react'

import Section from '@ui/wrappers/Section'
import Typography from '@ui/atoms/Typography'
import Icon from '@ui/atoms/Icon'
import Price from '@ui/molecules/Price'

type SummaryItem = {
  label: string
  price: number
}

interface Props {
  label: string
  price: number
  items: SummaryItem[]
  totalLabel: string
}

export default function Cart({ label, price, items = [], totalLabel }: Props) {
  if (price === 0) return null
  return (
    <Section classNameOuter="absolute left-1/2 -translate-x-1/2 top-24 w-full animate-fadein z-50">
      <Popover className="relative inset-0">
        {({ open }) => (
          <>
            <Popover.Button
              as="button"
              type="button"
              className={twMerge(
                'relative inset-0 z-20 flex w-full items-center justify-between rounded-none bg-white px-8 py-2',
                open && 'shadow-[0px_6px_8px_0px_rgba(0,0,0,0.25)]'
              )}
            >
              <Typography variant="H4" component="span">
                {label}
              </Typography>
              <div className="flex flex-row items-center gap-8">
                <Price price={price} />
                <Icon name={open ? 'chevron-up' : 'chevron-down'} size={32} />
              </div>
            </Popover.Button>
            <Popover.Panel className="absolute left-0 top-full z-10 w-full -translate-y-0.5 bg-white shadow-lg">
              {items.map((item) => (
                <div
                  className="flex w-full flex-row justify-between border-t-2 border-gray-200 py-2 pr-16 first:border-t-0 first:pt-2.5"
                  key={item.label}
                >
                  <Typography
                    variant="H4"
                    component="span"
                    className="px-8 font-normal"
                  >
                    {item.label}
                  </Typography>
                  <Price price={item.price} className="px-8 font-normal" />
                </div>
              ))}
              <div className="flex w-full flex-row justify-between border-t-2 border-gray-900 py-2 pr-16">
                <Typography variant="H4" component="span" className="px-8">
                  {totalLabel}
                </Typography>
                <Price price={price} className="px-8" />
              </div>
            </Popover.Panel>
          </>
        )}
      </Popover>
    </Section>
  )
}
