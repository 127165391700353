import { get } from 'lodash'
import {
  BasicCondition,
  Condition,
  ConditionDictionary,
  ConditionRange,
  ConditionTargetValue,
  ConditionValues,
  RefractionCondition,
  ResolvedCondition,
  NegationCondition,
  StrengthCondition,
} from '@type/conditions'
import { StepOption } from '@type/config'

const wrapNegation = (
  condition: ResolvedCondition | undefined,
  negation?: boolean
) => {
  if (!condition) {
    return undefined
  }
  if (negation) {
    return {
      selector: 'not',
      value: condition,
    } satisfies NegationCondition
  }
  return condition
}

const resolveCondition = (
  conditionOrRef: Condition,
  dictionary: ConditionDictionary,
  stack?: string[]
): ResolvedCondition | undefined => {
  if (conditionOrRef.selector === 'relation') {
    const ref = conditionOrRef.value
    const res = dictionary[ref]
    if (stack?.includes(ref)) {
      console.warn(`Condition ${ref} refers to itself`)
      return undefined
    }

    if (res?.selector === 'relation') {
      return wrapNegation(
        resolveCondition(res, dictionary),
        conditionOrRef.negation
      )
    }
    return wrapNegation(res, conditionOrRef.negation)
  }
  return conditionOrRef
}

const validateRange = (
  range: ConditionRange,
  value: number,
  withEquals?: boolean
) => {
  // This does an early return when condition is not met
  // We invert the condition so for range -2<=x<=2 we check if range[0] < -2
  const startValidation = withEquals ? basicValidators.lt : basicValidators.lte
  const endValidation = withEquals ? basicValidators.gt : basicValidators.gte

  if (range[0] !== null && startValidation(value, range[0])) {
    return false
  }
  if (range[1] !== null && endValidation(value, range[1])) {
    return false
  }
  return true
}

const getStrength = ({ sph, cyl }: { sph: number; cyl: number }) => {
  const absSph = Math.abs(sph)
  const absTotal = Math.abs(sph + cyl)
  const absCyl = Math.abs(cyl)

  return Math.max(absSph, absTotal, absCyl)
}

const validateSingleSideRefractionCondition = (
  condition: RefractionCondition,
  values: {
    sph: number
    cyl: number
    total: number
    add: number
  }
) => {
  const { target } = condition
  const { withEquals, singleSide, ...ranges } = target
  return Object.keys(ranges).every((key) => {
    const range = ranges[key as keyof typeof ranges]

    if (
      range &&
      !validateRange(range, values[key as keyof typeof values], withEquals)
    ) {
      return false
    }
    return true
  })
}

const validateSingleStrengthCondition = (
  condition: StrengthCondition,
  values: {
    sph: number
    cyl: number
  }
) => {
  const strength = getStrength(values)
  return validateRange(
    condition.target.range,
    strength,
    condition.target.withEquals
  )
}

const validateStrengthCondition = (
  condition: StrengthCondition,
  values: ConditionValues
) => {
  const { ref } = values
  if (!ref || typeof ref !== 'object') {
    return {
      result: false,
      errorKey: condition.errorKey,
    }
  }
  const left = validateSingleStrengthCondition(condition, {
    sph: ref.l_sph,
    cyl: ref.l_cyl,
  })
  const right = validateSingleStrengthCondition(condition, {
    sph: ref.r_sph,
    cyl: ref.r_cyl,
  })

  if (condition.target.singleSide) {
    return {
      result: left || right,
      errorKey: condition.errorKey,
    }
  }
  return {
    result: left && right,
    errorKey: condition.errorKey,
  }
}

const validateRefractionCondition = (
  condition: RefractionCondition,
  values: ConditionValues
) => {
  const { ref } = values
  if (!ref || typeof ref !== 'object') {
    return {
      result: false,
      errorKey: condition.errorKey,
    }
  }
  const left = validateSingleSideRefractionCondition(condition, {
    sph: ref.l_sph,
    cyl: ref.l_cyl,
    add: ref.l_add,
    total: ref.l_sph + ref.l_cyl,
  })

  const right = validateSingleSideRefractionCondition(condition, {
    sph: ref.r_sph,
    cyl: ref.r_cyl,
    add: ref.r_add,
    total: ref.r_sph + ref.r_cyl,
  })

  if (condition.target.singleSide) {
    return {
      result: left || right,
      errorKey: condition.errorKey,
    }
  }

  return {
    result: left && right,
    errorKey: condition.errorKey,
  }
}

const basicValidators: Record<
  BasicCondition['selector'],
  (value: ConditionTargetValue, target: ConditionTargetValue) => boolean
> = {
  eq: (value, target) => {
    if (typeof value === 'string' && typeof target === 'string') {
      return value.toLowerCase() === target.toLowerCase()
    }
    // If target is false, we want to check if value is truthy
    if (target === false) {
      return !value
    }
    return value === target
  },
  neq: (value, target) => {
    if (typeof value === 'string' && typeof target === 'string') {
      return value.toLowerCase() !== target.toLowerCase()
    }
    return value !== target
  },
  gt: (value, target) => Number(value) > Number(target),
  lt: (value, target) => Number(value) < Number(target),
  gte: (value, target) => Number(value) >= Number(target),
  lte: (value, target) => Number(value) <= Number(target),
}

const resolveTargetValue = (
  target: string | number | boolean,
  values: ConditionValues
) => {
  if (typeof target !== 'string') {
    return target
  }

  const resolved = get(values, target)

  if (
    typeof resolved === 'string' ||
    typeof resolved === 'number' ||
    typeof resolved === 'boolean'
  ) {
    return resolved
  }

  return target
}

const validateBasicCondition = (
  condition: BasicCondition,
  values: ConditionValues
) => {
  const { param, selector, target } = condition
  const value = get(values, param)
  const resolvedTarget = resolveTargetValue(target, values)

  if (typeof value === 'object') {
    console.warn(
      `Condition ${JSON.stringify(
        condition
      )} has an object value. This is not supported.`
    )
    return {
      result: false,
      errorKey: condition.errorKey,
    }
  }
  return {
    result: basicValidators[selector](value, resolvedTarget),
    errorKey: condition.errorKey,
  }
}

export type ValidationResult = {
  result: boolean
  errorKey?: string
}

const validateCondition = (
  conditionOrRef: Condition,
  values: ConditionValues,
  dictionary: ConditionDictionary
): ValidationResult => {
  const condition = resolveCondition(conditionOrRef, dictionary)
  if (!condition) {
    console.warn(`Condition ${conditionOrRef} not found in dictionary`)
    return {
      result: false,
    }
  }
  if (condition.selector === 'and') {
    const checked = condition.value.map((condition) =>
      validateCondition(condition, values, dictionary)
    )
    const invalid = checked.find((result) => !result.result)

    return invalid
      ? {
          result: false,
          errorKey: condition.errorKey || invalid.errorKey,
        }
      : {
          result: true,
          errorKey: condition.errorKey,
        }
  }
  if (condition.selector === 'or') {
    const checked = condition.value.map((condition) =>
      validateCondition(condition, values, dictionary)
    )
    const isValid = checked.some((result) => result.result)
    const invalid = checked.find((result) => !result.result)

    return isValid
      ? {
          result: true,
        }
      : {
          result: false,
          errorKey: condition.errorKey || invalid?.errorKey,
        }
  }
  if (condition.selector === 'refraction') {
    return validateRefractionCondition(condition, values)
  }

  if (condition.selector === 'strength') {
    return validateStrengthCondition(condition, values)
  }

  if (condition.selector === 'not') {
    const { result, errorKey } = validateCondition(
      condition.value,
      values,
      dictionary
    )
    return {
      result: !result,
      errorKey,
    }
  }

  // Not sure why TS is complaining here
  return validateBasicCondition(condition as BasicCondition, values)
}

export const getValidatedOptionParams = (
  option: StepOption,
  values: ConditionValues,
  conditions: ConditionDictionary
) => {
  const disabled = option.disabled
    ? validateCondition(option.disabled, values, conditions)
    : { result: false }
  const locked = option.locked
    ? validateCondition(option.locked, values, conditions)
    : {
        result: false,
      }
  return {
    // For compat with the old code spread the disabled into 2 values
    disabled: disabled?.result,
    disabledErrorKey: disabled?.errorKey,
    locked,
  }
}

export default validateCondition
